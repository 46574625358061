import { Col, Grid, Flex } from "@tremor/react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import getConfig from "next/config";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import discordIcon from "../../../public/images/discord_icon.png";

export default function Footer() {
  const { publicRuntimeConfig } = getConfig();
  const { locale } = useRouter();
  const content = getLanguage(translation, locale);

  return (
    <div className="text-sm">
      <Grid numItems={1} numItemsSm={4} className="gap-2">
        <Col>
          <Flex flexDirection="col" justifyContent="between" alignItems="start">
            <div className="text-3xl p-5">{content.title}</div>
          </Flex>
        </Col>
        <Col>
          <Grid numItems={1} numItemsSm={2} className="gap-2">
            <Col className="flex justify-end flex-col items-start min-h-[10rem]">
              <div className="pt-1 pl-5">
                <Link href="/auth/login">{content.login}</Link>
              </div>
              <div className="pt-1 pl-5">
                <Link href="/auth/register">{content.register}</Link>
              </div>
              <div className="pt-1 pl-5">
                <Link rel="nofollow" href="/agreement">
                  {content.regulamin}
                </Link>
              </div>
              <div className="pt-1 pl-5">
                <Link rel="nofollow" href="/privacy-policy">
                  {content.privacyPolicy}
                </Link>
              </div>
            </Col>
            <Col className="flex justify-end flex-col items-start min-h-[10rem]">
              <div className="pt-1 pl-5">
                <Link href="/legal-assistant">{content.donotpay}</Link>
              </div>
              <div className="pt-1 pl-5">
                <Link href="/medical-assistant">{content.med}</Link>
              </div>
              <div className="pt-1 pl-5">
                <Link lang={locale} href="/doc">
                  {content.doc}
                </Link>
              </div>
              <div className="pt-1 pl-5 pb-1">
                <Link href="/blog">{content.blog}</Link>
              </div>
            </Col>
          </Grid>
        </Col>
        <Col className="h-[50px] md:h-[200px]">
          <Flex
            className="h-full"
            flexDirection="col"
            justifyContent="between"
            alignItems="center"
          >
            <div className="p-3 mt-auto">{content.copyright}</div>
          </Flex>
        </Col>
        <Col className="pb-3 pt-3 pr-5 pl-5">
          <Flex
            className="h-full"
            flexDirection="col"
            justifyContent="end"
            alignItems="center"
          >
            <div className="">{content.contact}</div>
            <Link
              title="dicord invite link"
              href={publicRuntimeConfig.DISCORD_INVITE_LINK || ""}
              target="__blank"
            >
              <Image
                width={60}
                src={discordIcon}
                alt="discord icon"
                className="md-5 mt-2"
              />
            </Link>
          </Flex>
        </Col>
      </Grid>
    </div>
  );
}
